<template>
  <v-dialog data-app v-model="opened">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>
      <v-card-title>Добавление клинера</v-card-title>
      <v-card-text>
        <v-form ref="form"
                lazy-validation>
          <v-row class="row-d-block">
              <v-subheader>Имя</v-subheader>
              <v-text-field
                  v-model="cleaner.name"
                  outlined/>
          </v-row>
          <v-row class="row-d-block">
              <v-subheader>Телефон</v-subheader>
              <v-text-field
                  v-model="cleaner.phone"
                  outlined
                  type="tel"
                  v-mask="$store.state.setMask(cleaner.phone)"/>
          </v-row>
          <v-row class="row-d-block">
              <v-subheader>Улица</v-subheader>
              <v-autocomplete
                  :loading="loading"
                  v-model="cleaner.street"
                  no-data-text="Улиц нет"
                  :items="streets"
                  item-value="street"
                  @blur="cleaner.street=searchStreets"
                  item-text="street"
                  :search-input.sync="searchStreets"
                  outlined
                  hide-no-data
                  :no-filter="true"
                  @change="findStreet"/>
          </v-row>
<!--          <v-row class="row-d-block">-->
<!--              <v-subheader>Фото</v-subheader>-->
<!--              <v-file-input-->
<!--                  placeholder="Загрузить фото"-->
<!--                  outlined-->
<!--                  dense-->
<!--                  v-model="photo"-->
<!--                  accept="image/*"-->
<!--                  @change="getPhoto"-->
<!--                  prepend-icon="mdi-camera"-->
<!--              ></v-file-input>-->
<!--          </v-row>-->

        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn :loading="loading_save" @click="save();">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Add",
  data() {
    return {
      opened: false,
      cleaner: {street: {}},
      streets: [],
      loading: false,
      loading_save: false,
      searchStreets: null,
      photo: null,

    }
  },
  methods: {
    open() {
      this.opened = true
    },
    findStreet(str) {
      this.loading = true
      this.streets = [{street: str}].concat(this.streets);
      this.$store.state.server.request('order/findStreet', {region_id: '1', q: str}, (data) => {
        this.streets = [{street: str}].concat(data.response);
        this.loading = false
      }, ()=>{this.loading = false})
    },
    // getPhoto() {
    //
    // },
    save() {
      this.loading_save = true;
      this.$store.state.server.request('cleaner/create', this.cleaner, (data)=>{
        console.log('new cleaner', data)
        this.loading_save = false
        this.opened = false
        this.$root.notify('Клинер добавлен', 'success')
        this.$eventBus.$emit('cleanerUpdated')
      }, (data)=>{
        if (data && data.error) this.$root.notify(data.error, 'error')
        this.loading_save = false
      })
    }
  },
  watch: {
    searchStreets(val) {
      val && this.findStreet(val)
    },
  }
}
</script>

<style scoped>

</style>