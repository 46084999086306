<template>
  <v-app>
    <v-container data-app>
      <v-card>
        <v-card-title>Воронка клинеров</v-card-title>
        <v-card-actions>
          <DatePeriod @change="load" v-model="date"/>
        </v-card-actions>
        <v-card-actions>
          <v-row class="pa-2">
            <v-btn @click="statMode = 0; load()"
                   :style="statMode === 0 ? '' : `background: #eee !important;`" small class="mr-2">
              Ввели в црм в выбранном периоде
            </v-btn>
            <v-btn @click="statMode = 1; load()"
                   :style="statMode === 1 ? '' :`background: #eee !important;`" small>
              Перешли на этап в выбранном периоде
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>

      <v-card>
        <v-card-text>
          <div ref="funnelContainer" style="background: #333333;padding: 20px;border-radius: 10px;">
            <vue-funnel-graph v-if="funnelWidth" :values="funnelValues"
                              :gradient-direction="'horizontal'"
                              animated
                              :colors="funnelColors"
                              :width="funnelWidth"
                              :sub-labels="stats.filter(s=>s.source!=='Всего' && (+s.total || s.leads)).map(r=>r.source)"
                              :labels="funnelIndexes.map(i=>headers[i].text)"
                              :height="300"
            />
          </div>
          <v-data-table
              :headers="headers"
              :items="stats"
              sort-by="total"
              sort-desc
              :footer-props="{'items-per-page-options':[-1]}"
              class="stripped"
              loading-text="Загрузка..."
              mobile-breakpoint="0"
          >
            <template v-slot:item="{item, index}">
              <tr>
                <template v-for="(header, i) in headers">
                  <td v-if="header.value==='cons'">
                    <SavingParam style="width: 100px" @change="load" :initial-value="item.cons"
                                 v-if="isDateFullMonth && !['всего', '---'].includes(item.source.toLowerCase())"
                                 :name="`cleaner_source_${item.source}_${currentMonth}`"/>
                    <div v-else>{{ item.cons }}</div>
                  </td>
                  <td style="background: #abedff" v-else-if="header.value==='leads'">
                    <SavingParam style="width: 80px" @change="load" :initial-value="item.leads"
                                 v-if="isDateFullMonth && !['всего', '---'].includes(item.source.toLowerCase())"
                                 :name="`cleaner_source_leads_${item.source}_${currentMonth}`"/>
                    <div v-else>{{ item.leads }}</div>
                    <div v-if="+item.cons" style="font-size: 10px;color: #797979">
                      {{ $root.printCost(item.cons / item[header.value]) }}/кл
                    </div>
                  </td>
                  <td v-else-if="header.value==='source'">
                    {{ item.source }}
                  </td>
                  <td v-else-if="header.value==='revenue'" style="white-space: nowrap">
                    {{ $root.printCost(item.revenue) }}
                  </td>
                  <td v-else :style="{background: funnelIndexes.indexOf(i)>=0?'#abedff':''}">
                    <div
                        @click="$eventBus.$emit('openCleanersList', cleanerLists[item.source][header.value], header.value=='oform'?'Оформлен':'Добавлен в CRM', header.value=='oform'?(cleaner=>cleaner.datehire):null)"
                        style="cursor: pointer">{{ item[header.value] }}
                    </div>
                    <div v-if="+item.cons && +item[header.value]" style="font-size: 10px;color: #797979">
                      {{ $root.printCost(item.cons / item[header.value], 0, 1) }}/кл
                    </div>
                  </td>
                </template>
              </tr>
            </template>
          </v-data-table>
          <SourceSettings ref="SourceSettings"/>
          <v-btn @click="$refs.SourceSettings.open()">Настройка источников</v-btn>
        </v-card-text>
      </v-card>
      <v-card>
        <v-card-title>Уволенные клинеры</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="5">
              <v-list dense>
                <v-list-item v-for="item in fired" :key="i">
                  <v-row class="justify-space-between">
                    <v-col>{{ item.title }}</v-col>
                    <v-col cols="auto">
                      <v-list-item-content class="align-end">
                        {{ item.percent }}% ({{ item.count }} из {{ item.total }})
                      </v-list-item-content>
                    </v-col>
                  </v-row>
                </v-list-item>
              </v-list>
            </v-col>
            <!--            <v-col>-->
            <!--              <Doughnut-->
            <!--                  ref="doughnut_chart"-->
            <!--                  :chart-options="chartOptions"-->
            <!--                  :chart-data="doughnutChartData"-->
            <!--                  chart-id="fired-cleaners-chart"-->
            <!--                  :width="300"-->
            <!--              />-->
            <!--            </v-col>-->
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import Add from "@/views/cleaners/Add";
import NewCleaner from "@/views/cleaners/NewCleaner";
import RevenueIcon from "./RevenueIcon";
import SavingParam from "../../components/SavingParam";
import DatePeriod from "../../components/DatePeriod";
import {VueFunnelGraph} from 'vue-funnel-graph-js';
import SourceSettings from "./SourceSettings";

import {Doughnut} from "vue-chartjs/legacy"
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement, CategoryScale, LinearScale, PointElement
} from 'chart.js'
import ChartJSPluginDatalabels from "chartjs-plugin-datalabels";

ChartJS.register(ChartJSPluginDatalabels, Title, Tooltip, Legend, ArcElement, CategoryScale, LineElement, LinearScale, PointElement)

export default {
  name: "CleanersFunnel",
  components: {
    Doughnut,
    SourceSettings,
    DatePeriod,
    SavingParam,
    RevenueIcon,
    VueFunnelGraph,
    NewCleaner,
  },
  data() {
    return {
      stats: [],
      fired: [],
      funnelValues: [],
      cleanerLists: {},
      funnelWidth: 0,
      funnelIndexes: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      funnelColors: [
        ['#FFB178', '#FF3C8E'],
        ['#A0BBFF', '#EC77FF'],
        ['#A0F9FF', '#7795FF'],
        ['#c8ffbd', '#5cf53e'],
        ['#ffe4ab', '#ffb514'],
        ['#e898ff', '#d414ff'],
        ['#4bff31', '#2ca417'],
        ['#8ab7ff', '#5976a4'],
        ['#fff05e', '#a89e3d'],
        ['#ff6565', '#a23e3e'],
        ['#ff8c50', '#b05a2e'],
      ],
      headers: [
        {text: 'Источник', value: 'source'},
        {text: 'Отклики', value: 'leads'},
        {text: 'Введено в црм', value: 'total'},
        {text: 'Назначено интервью', value: 'interview_created'},
        {text: 'Пришли на интервью', value: 'interview_came'},
        {text: 'Прошли интервью', value: 'interview_passed'},
        {text: 'Назначена стажировка', value: 'became_student'},
        {text: 'Оформлены', value: 'oform'},
        {text: 'Получили 2 зп', value: 'two_months'},
        {text: 'Уволено/Не работает', value: 'fired'},
        {text: 'Расход за месяц', value: 'cons'},
        {text: 'Выручка', value: 'revenue'},
      ],
      date: [this.$moment().startOf('month').format('YYYY-MM-DD'), this.$moment().endOf('month').format('YYYY-MM-DD')],
      chartOptions: {
        responsive: true,
        cutout: 50,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            // display: false
          },
          datalabels: {
            color: '#fff',
            textAlign: 'center',
            font: {
              lineHeight: 1.3,
              size: 12,
              weight: 600
            },
            formatter: (value, ctx) => {
              return value > 0 ? `${ctx.chart.data.labels[ctx.dataIndex]} ${value}` : '';
            }
          }
        }
      },
      statMode: 0
    }
  },
  methods: {
    load() {
      this.$store.state.server.request('cleaner/statsOform', {date: this.date, statMode: this.statMode}, (data) => {
        this.stats = data.response;
        this.cleanerLists = data.cleanerLists;
        this.fired = data.fired;
        this.calcFunnelValues();
      })
    },
    calcFunnelValues() {
      let indexes = this.funnelIndexes;
      let values = [];
      this.stats.forEach((row) => {
        console.log({row})
        let isNotEmpty = false;
        for (let i in row) if (i !== 'source' && row[i]) {
          isNotEmpty = true
          break;
        }
        // if (row.source === 'Всего' || !(+row.total) && !(+row.leads)) return;
        if (row.source === 'Всего' || !isNotEmpty) return;
        indexes.forEach((index, i) => {
          let value = row[this.headers[index].value] || 0;
          if (typeof values[i] === 'undefined') values[i] = [];
          values[i].push(value || 0);
        });
      });
      if (!values.length) return;
      console.log(values);
      this.funnelValues = values;
      this.funnelWidth = this.$refs.funnelContainer.offsetWidth - 40;
      this.$nextTick(() => this.fillFunnelStats());
    },
    fillFunnelStats() {
      let blocks = this.$refs.funnelContainer.querySelectorAll('.svg-funnel-js__labels .svg-funnel-js__label');
      let totalRow = this.stats.find(row => row.source === 'Всего');
      blocks.forEach((block, index) => {
        let cost = totalRow.cons / totalRow[this.headers[this.funnelIndexes[index]].value];
        let prevBlock = block.querySelector('.funnel_custom_bottom_label');
        if (prevBlock) prevBlock.remove();
        if (!cost) return;
        let div = document.createElement('div');
        div.className = 'funnel_custom_bottom_label';
        div.innerText = this.$root.printCost(cost, false, true) + '/кл';
        block.append(div);
      });
    },
  },
  mounted() {
    this.load();
    this.getFiredCleaners();
  },
  created() {
    this.$eventBus.$on('funnelReload', () => this.load())
  },
  destroyed() {
    this.$eventBus.$off('funnelReload');
  },
  computed: {
    isDateFullMonth() {
      return this.$moment(this.date[0]).endOf('month').format('YYYY-MM-DD') === this.date[1] || this.date[0] === this.date[1];
    },
    currentMonth() {
      return this.date[0] === this.date[1] ? this.date[0] : this.$moment(this.date[0]).endOf('month').format('YYYY_MM');
    },
    // doughnutChartData() {
    //   let labels = [...this.$store.state.fireReasons, 'Другое'];
    //   let data = [];
    //   labels.forEach(label=>{
    //     let cleanersLengthByReason = this.cleaners.filter(cleaner=>(label === 'Другое' && !cleaner.fireReason && cleaner.comment_fire && +cleaner.status === 2) || (cleaner.fireReason === label && +cleaner.status === 2)).length;
    //     if ( cleanersLengthByReason) data.push(cleanersLengthByReason);
    //   })
    //   return {
    //     labels: labels.filter(label=>this.cleaners.filter(cleaner=>(label === 'Другое' && !cleaner.fireReason && cleaner.comment_fire && +cleaner.status === 2) || (cleaner.fireReason === label && +cleaner.status === 2)).length),
    //     datasets: [
    //       {
    //         backgroundColor: ['#486746', '#3f6080', '#e32b8d', '#dd163a',
    //           '#6b6b6b', '#110107', '#810233', '#810270',
    //           '#3b0281', '#023d81', '#028163', '#4e8102',
    //           '#817602', '#6b0202', '#02186b'],
    //         data
    //       }
    //     ]
    //   }
    // },
  }
}
</script>

<style>
.funnel_custom_bottom_label {
  font-size: 16px;
  position: absolute;
  bottom: 0;
  color: #ffffff;
  width: 100%;
  left: 0;
  text-align: center;
}
</style>
