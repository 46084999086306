<template>
  <v-dialog data-app @input="onToggle()" v-model="opened" :retain-focus="false" max-width="400px">
    <v-container style="padding: 0; min-height: auto">
      <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>
      <v-card style="margin-bottom: 0">
        <v-card-title>{{ cleaner_id ? 'Редактирование клинера' : 'Новый клинер' }}</v-card-title>
        <v-card-text>
          <v-form ref="new_cleaner_form">
            <v-row class="row-d-block">
              <v-subheader>Фамилия Имя</v-subheader>
              <v-text-field
                  v-model="cleaner.name"
                  outlined/>
            </v-row>
            <v-row class="row-d-block">
              <v-subheader>Телефон</v-subheader>
              <v-text-field
                  outlined
                  v-model="cleaner.phone"
                  v-mask="$store.state.setMask(cleaner.phone)"
              />
            </v-row>
            <v-row class="row-d-block">
              <v-subheader>Источник рекламы</v-subheader>
              <v-select
                  v-model="cleaner.source"
                  outlined
                  :items="$store.state.user.cleanerSources"
                  :rules="[v=>!!v || 'Укажите источник рекламы']"
                  validate-on-blur
              />
            </v-row>
            <v-row class="row-d-block" v-if="cleaner_id">
              <v-subheader>Возраст</v-subheader>
              <v-row>
                <v-text-field
                    class="mr-3"
                    v-model="cleaner.age"
                    outlined
                    @input="changeBirthday"
                    type="number"/>
                <v-text-field
                    v-model="cleaner.birthday"
                    outlined
                    @change="changeAge"
                    type="date"/>
              </v-row>
            </v-row>
            <v-row>
              <v-subheader>Наличие опыта</v-subheader>
              <v-checkbox
                  v-model="cleaner.experience"
                  true-value="1"
                  false-value="0"
              />
            </v-row>
            <v-row class="row-d-block">
              <v-subheader>Статус кандидата</v-subheader>
              <v-select
                  v-model="cleaner.status2"
                  outlined
                  :items="[{value:'new', text:'Новый'},{value:'waiting', text:'Перезвонить'},{value:'interview', text:'Собеседование'},{value:'archive', text:'Архив'},]"
                  item-text="text"
                  item-value="value"
              />
            </v-row>
            <v-row class="row-d-block">
              <v-subheader>Комментарий</v-subheader>
              <v-textarea
                  v-model="cleaner.comment"
                  rows="2"
                  outlined/>
            </v-row>
            <template v-if="!['new', 'waiting'].includes(cleaner.status2)">
              <v-row>
                <v-col cols="5" class="px-0">
                  <v-subheader>Дата собесед</v-subheader>
                  <Date v-model="cleaner.interview_dt_date"/>
                </v-col>
                <v-col cols="5" class="px-0">
                  <v-subheader>Время собесед</v-subheader>
                  <v-text-field
                      v-model="cleaner.interview_dt.time"
                      outlined
                      v-mask="'##:##'"
                  />
                </v-col>
                <v-col cols="2" class="px-0">
                  <v-subheader>СМС
                    <InfoTip
                        value="Отправить смс о собеседовании клинеру? Текст смс настраивается над списком клинеров, кнопка «СМС о собеседовании»"></InfoTip>
                  </v-subheader>
                  <v-checkbox
                      v-model="cleaner.send_interview_sms"
                      true-value="1"
                      false-value="0">
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-btn :class="{default: [0,2].includes(cleaner.interview_missed*1)}"
                       @click="[0,2].includes(cleaner.interview_missed*1) ? cleaner.interview_missed=1:''" class="mr-2">
                  Пришел
                </v-btn>
                <v-btn :class="{default: cleaner.interview_missed!=2}"
                       @click="(cleaner.interview_missed=2) && (cleaner.isNew = '0') && (cleaner.status = '2')"
                       class="mr-2">
                  Не пришел
                </v-btn>
              </v-row>
              <v-row class="mt-2" v-if="![0,2].includes(cleaner.interview_missed*1)">
                <v-btn :class="{default: cleaner.interview_missed!=3}"
                       @click="(cleaner.interview_missed=3) && (cleaner.isNew = '1') && (cleaner.status = '0')"
                       class="mr-2">
                  Прошел
                </v-btn>
                <v-btn :class="{default: cleaner.interview_missed!=4}"
                       @click="(cleaner.interview_missed=4) && (cleaner.isNew = '0') && (cleaner.status = '2')"
                       class="mr-2">
                  Не прошел
                </v-btn>
              </v-row>
            </template>
            <v-row class="row-d-block">
              <v-subheader>Статус работы</v-subheader>
              <v-select
                  v-model="cleaner.status"
                  outlined
                  :items="cleanerStatuses"
                  item-text="text"
                  item-value="value"
              />
            </v-row>
            <v-row v-if="cleaner_id" class="row-d-block">
              <v-subheader>Стажер</v-subheader>
              <v-checkbox
                  v-model="cleaner.isNew"
                  true-value="1"
                  @change="cleaner.status = Number(cleaner.isNew) ? '0' : cleaner.status"
                  false-value="0">
              </v-checkbox>
            </v-row>
            <v-row class="row-d-block" v-if="cleaner_id">
              <v-subheader>Адрес проживания</v-subheader>
              <v-autocomplete
                  :loading="loading_street"
                  v-model="cleaner.street"
                  :items="cleaner.street? [cleaner.street].concat(streets):streets"
                  hide-no-data
                  item-value="street"
                  item-text="street"
                  :no-filter="true"
                  :search-input.sync="searchStreets"
                  outlined
                  @blur="cleaner.street = searchStreets"
              />
            </v-row>
            <v-row v-if="cleaner_id" class="row-d-block">
              <v-subheader>Тренинг</v-subheader>
              <v-row>
                <v-col>
                  <v-checkbox
                      v-model="cleaner.trainingClean"
                      true-value="1"
                      false-value="0">
                    <template v-slot:prepend>
                      <v-subheader>Обычная</v-subheader>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                      v-model="cleaner.trainingGeneral"
                      true-value="1"
                      false-value="0">
                    <template v-slot:prepend>
                      <v-subheader>Генеральная</v-subheader>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                      v-model="cleaner.trainingRemont"
                      true-value="1"
                      false-value="0">
                    <template v-slot:prepend>
                      <v-subheader>После ремонта</v-subheader>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-row>
            <v-row v-if="cleaner_id">
              <v-subheader>Документы</v-subheader>
              <v-file-input
                  v-model="cleaner.doc"
                  @change="setDoc"
                  prepend-icon="mdi-paperclip"
                  outlined
                  placeholder="Выберите документ"
                  hide-input
              ></v-file-input>
              <v-row v-if="cleaner.documents" v-for="(doc, i) in cleaner.documents" :key="doc.url">
                <span style="max-width: 320px">
                  <a :href="doc.url" target="_blank" style="overflow: hidden">{{ doc.name }}</a>
                  <v-icon @click="deleteDoc(i)">mdi-close</v-icon>
                </span>
              </v-row>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-btn @click="save" :loading="loading_save">Сохранить</v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script>
import InfoTip from "../../components/InfoTip";
import Date from "../../components/Date";

export default {
  name: "NewCleaner",
  components: {Date, InfoTip},
  data() {
    let cleanerInitial = {interview_dt: {date: ''}, status: '3', cleaner_id: null};
    return {
      opened: false,
      cleaner: cleanerInitial,
      cleanerInitial,
      cleaner_id: null,
      menu_date_of_birth: false,
      loading_save: false,
      loading_street: false,
      streets: [],
      searchStreets: null,
      cleanerStatuses: [
        {text: 'Новый', value: '3'},
        {text: 'Не работает', value: '1'},
        {text: 'Работает', value: '0'},
        {text: 'Уволен', value: '2'},
      ]
    }
  },
  methods: {
    open(cleaner_id = null) {
      this.opened = true;
      this.cleaner = {interview_dt: {}, status: '1', cleaner_id: null}
      this.cleaner_id = cleaner_id
      this.cleaner = {...this.cleanerInitial};
      if (this.cleaner_id) this.getCleaner()
      else this.cleaner.send_interview_sms = '1';
    },
    onToggle() {
      this.cleaner = {interview_dt: {}, status: '1', cleaner_id: null}
      this.cleaner_id = null
    },
    save() {
      if (this.$refs.new_cleaner_form.validate()) {
        this.loading_save = true
        let params = {...this.cleaner};
        params.documents = JSON.stringify(this.cleaner.documents)
        params.interview_dt.date = params.interview_dt_date;
        this.$store.state.server.request(this.cleaner_id ? `cleaner/update/${this.cleaner_id}` : 'cleaner/create', params, (data) => {
          this.loading_save = false
          this.$root.notify('Клинер сохранён', 'success')
          this.opened = false
          this.$eventBus.$emit('cleanerUpdated')
        }, (data) => {
          this.loading_save = false
          if (data && data.error) {
            if (data.exception_type) {
              let info = data.exception_type.split(':');
              if (info[0] === 'exists') this.$eventBus.$emit('openCleanerCard', {n_id: info[1], status: info[2]});
            }
            this.$root.notify(data.error, 'error')
          }
        })
      }
    },
    getCleaner() {
      this.$store.state.server.request(`cleaner/get/${this.cleaner_id}`, {}, (data) => {
        data.response.interview_dt_date = data.response.interview_dt.date
        this.cleaner = data.response
        try {
          this.cleaner.documents = this.cleaner.documents ? JSON.parse(this.cleaner.documents) : [];
        } catch (e) {
        }
        if (!Array.isArray(this.cleaner.documents)) this.cleaner.documents = []
        this.changeAge();
        this.$forceUpdate()
      })
    },
    findStreet(str) {
      this.loading = true
      this.streets = [{street: str}].concat(this.streets);

      this.$store.state.server.request('order/findStreet', {region_id: '1', q: str}, (data) => {
        this.streets = [{street: str}].concat(data.response);

        this.loading = false
      }, () => {
        this.loading = false;
      })
    },
    changeAge() {
      this.cleaner.age = this.cleaner.birthday ? this.$moment().diff(this.cleaner.birthday, 'years', false) : '';
    },
    changeBirthday() {
      let date = this.cleaner.birthday ? this.cleaner.birthday.split('-').splice(1).join('-') : '01-01';
      this.cleaner.birthday = this.cleaner.age ? this.$moment().subtract(this.cleaner.age, 'years').format('YYYY-') + date : '';
    },
    setDoc() {
      let file = this.cleaner.doc;
      const formData = new FormData()
      console.log({file});
      formData.append('file', file, file.name)
      this.axios.post('https://cleanbros.ru/upload', formData)
          .then(data => {
            this.cleaner.doc = null;
            this.cleaner.documents.push({url: data.data.response, name: file.name})
            this.$root.notify('Документ добавлен', 'success')
            console.log(this.cleaner.documents);
          })
          .catch(e => {
            this.$root.notify(e, 'error')
          })
    },
    deleteDoc(key) {
      delete this.cleaner.documents[key]
      this.cleaner.documents = this.cleaner.documents.filter(i => i);
      this.$forceUpdate()
    }
  },
  watch: {
    searchStreets(val) {
      val && this.findStreet(val)
    },

  }
}
</script>

<style scoped>

</style>
