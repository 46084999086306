<template>
  <v-dialog persistent max-width="320px" scrollable data-app v-model="opened">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="24">mdi-close</v-icon>
      <v-card-text class="mt-3">
        <div v-for="(source, index) in sources" v-if="source"
             style="display: flex;align-items: center;justify-content: space-between;">
          <div>{{ source }}</div>
          <v-btn @click="confirm(`Удалить источник ${source}?`) && sources.splice(index, 1)" text
                 style="text-transform: none" color="red">
            Удалить
          </v-btn>
        </div>
        <div style="display: flex;align-items: end;gap: 10px">
          <v-text-field v-model="newSource" label="Добавить источник"/>
          <v-btn small style="text-transform: none" @click="sources.push(newSource);newSource=''">Добавить</v-btn>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn block @click="save">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "SourceSettings",
  data() {
    return {
      sources: [],
      newSource: '',
      opened: false,
    }
  },
  methods: {
    open() {
      this.opened = true;
      this.sources = this.$store.state.user.cleanerSources.slice();
    },
    confirm: title => window.confirm(title),
    save() {
      this.$store.state.server.request('user/setParam', {
        name: 'cleanerSources',
        value: this.sources.map(s => s.trim()).filter(s => s),
      }, () => {
        this.$store.state.user.cleanerSources = this.sources;
        this.opened = false;
        this.$eventBus.$emit('funnelReload')
      })
    }
  }
}
</script>
